import * as React from "react";
import Seo from "../seo/index.jsx";
import MainLayout from "../components/layouts/mainLayout.js";
import ComingSoon from "../components/sections/coming-soon.js";
import '../styles/pages/index.scss';

const IndexPage = () => {

  const [activeDay, setActiveDay] = React.useState(false);

  const changeDayNight = () => {
    setActiveDay(!activeDay);
  }

  return (
    <MainLayout dayNightHandler={changeDayNight} activeDay={activeDay}>
      <Seo />
      <div className={`index-page`}>
        <ComingSoon activeDay={activeDay} />
      </div>
    </MainLayout>
  )
}

export default IndexPage
