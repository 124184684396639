import chill_audio from '../audios/Chill.mp3'
import flowing_audio from '../audios/Flowing.mp3'
import gravewalker_audio from '../audios/Gravewalker.mp3'
import just_die_already_audio from '../audios/Just_Die_Already.mp3'
import tartarus_audio from '../audios/Tartarus.mp3'
import the_garden_audio from '../audios/The_Garden.mp3'
import the_key_audio from '../audios/The_Key.mp3'
import time_audio from '../audios/Time.mp3'

const audioData = [
    {
        audio: chill_audio,
        info: {
            songName: 'Chill',
            artist: 'Efficio',
        }
    }, 
    {
        audio: flowing_audio,
        info: {
            songName: 'Flowing',
            artist: 'Efficio',
        }
    },
    {
        audio: gravewalker_audio,
        info: {
            songName: 'Gravewalker',
            artist: 'Efficio',
        }
    },
    {
        audio: just_die_already_audio,
        info: {
            songName: 'Just Die Already',
            artist: 'Efficio',
        }
    },
    {
        audio: tartarus_audio,
        info: {
            songName: 'Tartarus',
            artist: 'Efficio',
        }
    },
    {
        audio: the_garden_audio,
        info: {
            songName: 'The Garden',
            artist: 'Efficio',
        }
    },
    {
        audio: the_key_audio,
        info: {
            songName: 'The Key',
            artist: 'Efficio',
        }
    },
    {
        audio: time_audio,
        info: {
            songName: 'Time',
            artist: 'Efficio',
        }
    }
]

export default audioData;