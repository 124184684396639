import * as React from "react";
import '../../styles/global.scss';
import '../../styles/components/layouts/mainLayout.scss';
import Header from "../common/header.js";

const MainLayout = ({ children, dayNightHandler, activeDay, header = true }) => {
  return (
    <div className={`main-layout`}>
      {header? <Header dayNightHandler={dayNightHandler} activeDay={activeDay} />: ''}
      {children}
    </div>
  )
}

export default MainLayout


