import React, { useEffect } from "react";
import '../../styles/components/sections/coming-soon.scss';
import day_video from '../../videos/day.mp4';
import night_video from '../../videos/night.mp4';
import arLogo from '../../images/ar-big-group.png'
import discordIcon from '../../images/discord-icon.png'
import instaIcon from '../../images/insta-icon.png'
import twitterIcon from '../../images/twitter-icon.png'
import cgarIcon from '../../images/cgar-icon.png'
import visionIcon from '../../images/vision-icon.png'
import astheonIcon from '../../images/astheon-icon.png'
import prevIcon from '../../images/prev-icon.png'
import prevGlowIcon from '../../images/prev-icon-glow.png'
import playIcon from '../../images/play-icon.png'
import playGlowIcon from '../../images/play-icon-glow.png'
import nextIcon from '../../images/next-icon.png'
import nextGlowIcon from '../../images/next-icon-glow.png'
// import musicBarGlow from '../../images/music-bar-glow.png'
// import musicBarSeekGlow from '../../images/music-bar-seek-glow.png'
import useAudio from '../../hooks/useAudio'
import audioData from '../../data/audioData';

const ComingSoon = ({activeDay}) => {
  const [playing, toggle, playNext, playPrev, songInfo] = useAudio(audioData);

  return (
    <div className={`coming-soon`}>
      <video src={day_video} type={`video/mp4`} className={`bg-video ${activeDay? 'active': ''}`} id={`bg-video-1`} loop muted autoPlay playsInline>
        Sorry, your browser doesn't support HTML5 video tag.
      </video>
      <video src={night_video} type={`video/mp4`} className={`bg-video ${!activeDay? 'active': ''}`} id={`bg-video-2`} loop muted autoPlay playsInline>
        Sorry, your browser doesn't support HTML5 video tag.
      </video>
      <div className={`mid-logo-wrapper`}>
        <img src={arLogo} alt={``} className={``} />
        <p>
          Coming Soon
        </p>
      </div>

      <div className={`bottom-wrapper`}>
        <div className={`social-links`}>
          <a href={`https://discord.gg/YWxeEftR2y`} target={`_blank`} title={`Discord`}>
            <img src={discordIcon} alt={`Discord Icon`} className={``} />
          </a>
          <a href={`https://instagram.com/animusregnum.eth?igshid=MzRlODBiNWFlZA==`} target={`_blank`} title={`Instagram`}>
            <img src={instaIcon} alt={`Instagram Icon`} className={``} />
          </a>
          <a href={`https://twitter.com/AnimusRegnum?t=FPP6xBSLkbe_TlN4itRJFg&s=09`} target={`_blank`} title={`Twitter`}>
            <img src={twitterIcon} alt={`Twitter Icon`} className={``} />
          </a>
        </div>
        <div className={`web-links`}>
          <a href={`https://animus-regnum.gitbook.io/cgar/introduction/what-is-animus-regnum`} target={`_blank`} title={`CGAR`}>
            <div className={`item-wrapper`}>
              <img src={cgarIcon} alt={`CGAR Icon`} className={`img-1`} />
              <span>
                CGAR
              </span>
            </div>
          </a>
          <a href={`https://tr.ee/6NmSKW9FwY`} target={`_blank`} title={`Vision`}>
            <div className={`item-wrapper`}>
              <img src={visionIcon} alt={`Vision Icon`} className={`img-2`} />
              <span>
                Vision
              </span>
            </div>
          </a>
          <a href={`https://app.manifold.xyz/c/astheon`} target={`_blank`} title={`Astheon`}>
            <div className={`item-wrapper`}>
              <img src={astheonIcon} alt={`Astheon Icon`} className={`img-3`} />
              <span>
                Astheon
              </span>
            </div>
          </a>
        </div>
        <div className={`song-player-wrapper`}>
          <div className={`song-info`}>
            <div className={`song-name`}>
              { songInfo?.songName}
            </div>
            <div className={`artist-name`}>
              {songInfo?.artist}
            </div>
          </div>
          <div className={`player`}>
            <div className={`btn prev-btn-wrapper`} title={`Previous Song`} onClick={playPrev}>
              <img src={prevIcon} alt={`Previous Song Icon`} className={`active ${!playing ? "active" : ""}`} />
              <img src={prevGlowIcon} alt={`Previous Song Icon`} className={`glow ${playing ? "active" : ""}`} />
            </div>
            <div className={`btn play-btn-wrapper`} title={`Play`} onClick={toggle}>
              <img src={playIcon} alt={`Play Icon`} className={`${playing ? "active" : ""}`} />
              <img src={playGlowIcon} alt={`Play Icon`} className={`glow ${!playing ? "active" : ""}`} />
            </div>
            <div className={`btn next-btn-wrapper`} title={`Next Song`} onClick={playNext}>
              <img src={nextIcon} alt={`Next Song Icon`} className={`active ${!playing ? "active" : ""}`} />
              <img src={nextGlowIcon} alt={`Next Song Icon`} className={`glow ${playing ? "active" : ""}`} />
            </div>
          </div>
          {/*<div className={`music-bar-wrapper`}>
              <img src={musicBarGlow} alt={``} className={``} />
              <img src={musicBarSeekGlow} alt={``} className={`seeker`} />
            </div>*/}
        </div>
      </div>
    </div>
  )
}

export default ComingSoon
