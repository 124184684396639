import * as React from "react"
import {Helmet} from "react-helmet"
import {graphql, useStaticQuery} from "gatsby"
import {useLocation} from "@reach/router"
//import arMini from '../images/ARmini.png';

const Seo = ({
               title = "",
               description = "",
               pathname = "",
               children = null,
             }) => {
  const location = useLocation()
  const {
    site: {siteMetadata},
  } = useStaticQuery(graphql`
      query {
          site {
              siteMetadata {
                  siteTitle
                  siteTitleDefault
                  siteUrl
                  hrefLang
                  siteDescription
                  twitter
              }
          }
      }
  `)

  const {
    siteTitle,
    siteTitleDefault,
    siteUrl,
    siteDescription,
    hrefLang,
    twitter,
  } = siteMetadata

  const seo = {
    title: title || siteTitleDefault,
    description: description || siteDescription,
    url: pathname ? `${siteUrl}${pathname}` : location.href,
  }

  return (
    <Helmet
      title={title}
      defaultTitle={siteTitleDefault}
      titleTemplate={`%s | ${siteTitle}`}
    >
      <html lang={hrefLang}/>
      <meta name="description" content={seo.description}/>
      {/*<meta name="image" content={arMini}/>*/}
      <meta property="og:title" content={seo.title}/>
      <meta property="og:url" content={seo.url}/>
      <meta property="og:description" content={seo.description}/>
      {/*<meta property="og:image" content={arMini}/>*/}
      <meta property="og:type" content="website"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content={seo.title}/>
      <meta name="twitter:url" content={seo.url}/>
      <meta name="twitter:description" content={seo.description}/>
      {/*<meta name="twitter:image" content={arMini}/>*/}
      <meta name="twitter:creator" content={twitter}/>
      {/*<link
        rel="icon"
        href={arMini}
      />*/}

      {children}
    </Helmet>
  )
}

export default Seo
