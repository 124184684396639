import * as React from "react";
import '../../styles/components/common/header.scss';
import logo from '../../images/ar-header-logo.png';
import dn_dark from '../../images/daynight-dark-btn.png';
import dn_light from '../../images/daynight-light-btn.png';

const Header = ({dayNightHandler, activeDay}) => {

  return (
    <div className={`header`}>
      <div className={`no-container`}>
        <div className={`logo-wrapper`}>
          <img src={logo} alt={``} className={``} />
        </div>
        <div className={`day-mode-btn-wrapper`}>
          <div className={`mode-btn`} title={`Switch Day/Night Mode`} onClick={dayNightHandler}>
            <img src={dn_dark} alt={``} className={`mode-btn-image ${!activeDay? 'active': ''}`} />
            <img src={dn_light} alt={``} className={`mode-btn-image ${activeDay? 'active': ''}`} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
