import React, { useState, useEffect } from "react";

const useAudio = playlist => {
    const [audioIndex, setAudioIndex] = useState(0);
    const [audio, setAudio] = useState(null);
    const [songInfo, setSongInfo] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [autoPlay, setAutoPlay] = useState(false);
    const [eventReady, setEventReady] = useState(false);

    const getNextIndex = () => {
        if (audioIndex === (playlist.length - 1)) return 0;
        else return audioIndex + 1;
    }

    const getPrevIndex = () => {
        if (audioIndex === 0) return playlist.length - 1;
        else return audioIndex - 1;
    }

    const toggle = () => {
        playing ? pauseAudio() : playAudio();
    }

    const playNext = () => {
        setAudioIndex(getNextIndex());
        setAutoPlay(true);
    };

    const playPrev = () => {
        setAudioIndex(getPrevIndex());
        setAutoPlay(true);
    };

    const playAudio = () => {
        if (audio) {
            audio.play();
            setPlaying(true);
        }
    }

    const pauseAudio = () => {
        if(audio) {
            audio.pause();
            setPlaying(false);
        }
    }

    useEffect(() => {
        pauseAudio();
        const newAudio = new Audio(playlist[audioIndex].audio);
        setSongInfo(playlist[audioIndex].info);
        setAudio(newAudio);
    }, [audioIndex])

    useEffect(() => {
        if(audio && autoPlay) {
            playAudio();
        }

        if (audio && !eventReady) {
            setEventReady(true);
            audio.addEventListener('ended', playNext);
            return () => {
                audio.removeEventListener('ended', playNext);
            };
        }
    }, [audio])
  
    return [playing, toggle, playNext, playPrev, songInfo];
  };

  export default useAudio;